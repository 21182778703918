import PropTypes from "prop-types";
import React, { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch , Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import process from "process";
import './assets/scss/style.scss';


// home pages

const Home = lazy(() =>
  import("./pages/home/Home")
);
const HomeIntro = lazy(() =>
  import("./pages/home/HomePageIntro")
);

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/shop"));



// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// blog pages
const BlogStandard = lazy(() => import("./pages/blog/blog-mobile"));
const BlogNoSidebar = lazy(() => import("./pages/blog/blog"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/blogpost")
);

// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/login"));
const StripeCheckout = lazy(() => import("./payment/Checkout.jsx"))
const Success = lazy(()=>import("./payment/Success.jsx"));
const ChooseaPlane = lazy(()=> import("./pages/other/ChoosePlan.jsx"))
const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Results = lazy(() => import('./pages/other/results'));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Register = lazy(() => import("./pages/other/Register"));
const ViewOrder =lazy(() => import("./pages/other/vieworder.jsx"))
const PaymentSuccess = lazy(() => import("./pages/other/PaymentSuccess.jsx"))
const RegisterSuccess = lazy(() => import("./pages/other/RegisterSuccess"))
const RefundAndReturn =lazy(() => import("./pages/other/refundandreturn"))
const GeneralSearch =lazy(() => import("./pages/other/GeneralSearch"))
const BlogDiscalimer =lazy (()=> import('./pages/other/BlogDiscalimer'));
const PrivacyPolicy = lazy(()=>import('./pages/other/PrivacyPolicy.jsx'))
const CookiesPolicy = lazy (()=>import('./pages/other/CookiesPolicy.jsx'))
const TermsAndConditions = lazy (()=> import('./pages/other/TermsandConditions.jsx'))
const ResetPassword = lazy (()=> import('./pages/other/resetPassword.jsx'))
const App = () => {


  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/home"}
                  component={Home}
                />

                <Route
                  exact
                  path={process.env.PUBLIC_URL +"/home"}
                  component={Home}
                />
                {/* shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopGridStandard}
                />


                {/* shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                {/* Blog pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/blog-mobile"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog"}
                  component={BlogNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blogpost/:title"}
                  component={BlogDetailsStandard}
                />

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/my-account"}
                  component={MyAccount}
                />
                
                <Route
                  path={process.env.PUBLIC_URL + "/login"}
                  component={LoginRegister}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />


                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                 <Route
                  path={process.env.PUBLIC_URL + "/StripeCheckout"}
                  component={StripeCheckout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/Success"}
                  component ={Success}
                  />
                 <Route
                  path={process.env.PUBLIC_URL + "/ChoosePlan"}
                  component={ChooseaPlane}
                />
                 <Route path={process.env.PUBLIC_URL + '/Register'}
                component={Register}
                /> 
              
                 <Route path={process.env.PUBLIC_URL + '/results'}
                component={Results}
                />
                  <Route path={process.env.PUBLIC_URL + '/vieworder'}
                component={ViewOrder}
                />
                <Route path={process.env.PUBLIC_URL + '/PaymentSuccess'}
                  component={PaymentSuccess}/>

                <Route path={process.env.PUBLIC_URL + '/RegisterSuccess'}
                  component={RegisterSuccess}/>  

                 
                <Route path={process.env.PUBLIC_URL + '/refundandreturn'}
                  component={RefundAndReturn}/> 


                <Route path={process.env.PUBLIC_URL + '/GeneralSearch'}
                  component={GeneralSearch}/> 
                
                <Route path={process.env.PUBLIC_URL + '/blogdiscalaimer'}
                    component = {BlogDiscalimer}/>  

                <Route path={process.env.PUBLIC_URL + '/privacypolicy'}
                    component = {PrivacyPolicy}/>  
                <Route path={process.env.PUBLIC_URL + '/cookiespolicy'}
                    component = {CookiesPolicy}/>  
                <Route path={process.env.PUBLIC_URL + '/termsandconditions'}
                    component = {TermsAndConditions}/> 
                <Route path={process.env.PUBLIC_URL + '/reset-password'}
                    component = {ResetPassword}/>  
                <Route exact component={Home} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
